.ul .li{
  margin: 0;
  padding: 0;
  text-decoration:none;
}

.footer{
  background-color: #0F2925;
}

.container{
  color: #F5FFF9;
  font-family: 'Mulish', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
}

.container{
  align-items: flex-start;
}

.footerList {
  width: 100%;
}


.logo{
  height: 60px;
  width: 172px;
}

.infoText{
  font-size: 16px;
  line-height: 120%;
  margin-top: 20px;
}

.title{
  font-weight: 700;
  font-size: 20px;
}

.adress{
  text-decoration:none;
  color: #F5FFF9;
}

.footerItem{
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.social{
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.social .link:first-child {
  margin-right: 40px;
}

.social .img{
  color: #F5FFF9;
}
.social .img:hover,
.social .img:focus{
  background-color: #1f574f;
  transition: all 1s ease-out;
}

.link{
  color: #F5FFF9;
  text-decoration: none;
}
.link:hover,
.link:focus{
  color: #F5D1DE;
}

.link:visited{
   color:  #F5D1DE;
}

.btn{
  margin-top: 20px;
  width: fit-content;
}

.clutch{
  height: 60px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .footerItemWrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

@media (min-width: 1200px) {
  .social{
    margin-top: 40px;
  }
}

@media (min-width: 1400px) {
  .footerList {
    display: flex;
  }

  .footerAllItemsWrap{
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .infoText{
    width: 225px;
    margin-right: 180px;
  }

  .footerItem{
    margin-top: 0;
  }

  .footerItemWrap{
    display: flex;
    width: auto;
  }
  .social{
    margin: 0;
    margin-top: 40px;
  }
}