.сontainer {
  width: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px)  {
  .сontainer {    
    margin: 0 auto;
    padding: 80px 0px;
    max-width: 648px;
  }
}   

@media (min-width: 1200px) {
  .сontainer {
    padding: 125px 0px;
    max-width: 960px;
  }
}

@media (min-width: 1400px) {
  .сontainer {
    max-width: 1150px;
  }
}

@media (min-width: 1600px) {
  .сontainer {
    max-width: 1250px;
  }
}

@media (min-width: 1920px) {
  .сontainer {
    max-width: 1450px;
  }
}