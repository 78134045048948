.text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #012310;
  overflow: hidden;
  font-family: 'Mulish', sans-serif;
}

.fade .text{
  max-height: 200px;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
  margin-top: 15px;
}

.notfade .text{
  max-height: 1000px;
}

.notfade{
  max-height: 1000px;
  transition: all 10s ; 
}

.fade {
  max-height: 200px;
  transition: all 1s cubic-bezier(0, 1, 0, 1); 
}

.fade.notfade {
  max-height: 1000px !important;
  -webkit-mask-image: linear-gradient(180deg, rgb(255, 255, 255) 100%, transparent);
  transition: all 1s ; 
}

.showmoreText{
  font-style: italic;
}

.showmoreWrapper,
.showlessWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}

.showlessWrapper{
  margin-top: 20px; 
}

.showlessWrapper{
  flex-direction: column-reverse; 
}

.dropdownIcon,
.dropdownIconRotate{
  width: 10px;
  height: 10px;
  transition: all 0.35s cubic-bezier(0.72, 1.2, 0.71, 0.72);
}

.dropdownIconRotate{
  transform: rotate(180deg);
}

.text a{
  text-decoration: none;
  color: #012310;
}

.text a:hover{
  color: #9D0045;
}

@media (min-width: 768px)  {
  .text {
    font-size: 18px;
    max-width: 600px;
  }   

  .dropdownIcon,
  .dropdownIconRotate{
    width: 12px;
    height: 12px;
  }
}    
  
@media (min-width: 1200px) {
  .fade {
      margin-top: 20px;
    }
  .text {
    max-width: 945px;
  }
}

@media (min-width: 1400px) {
  .text {
    max-width: 1000px;
  }   
}

@media (min-width: 1920px) {
  .text {
    font-size: 20px;
    max-width: 1100px;
  }  
}