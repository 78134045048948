.layout{
  padding-top: 60px;
}

.cookiesContest div{
  pointer-events: none;
}

.cookiesContest div div{
  pointer-events: all;
  margin: 0 auto;
}

.cookiesWrapper{
  display: flex;
  align-items: center;
}

p.cookies{
  font-family: "Mulish", sans-serif;
  margin-bottom: 0;
  color: #000000;
}

.link{
  font-family: "Mulish", sans-serif;
  color: #ffffff;
  text-decoration: none
}

.link:hover,
.link:focus {
  color: #F5D1DE;
}

@media (min-width: 768px) {
  .layout{
    padding-top: 80px;
  }
}
