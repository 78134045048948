.btnWrapper{
  position: fixed;
  z-index: 20;
  bottom: 100px;
  right: 5px;
  padding: 8px;
  height: 40px;
  width: 40px;
  background-color: #b50050;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all .5s ease-in-out;
  opacity: 0.5;
}

.img{  
  position: absolute;
  top: 25%;
  height: 24px;
  width: 24px;
  color: #E6FEF1;
  transform: rotate(180deg);
}

.img div{
  height: 18px;
  width: 24px;
  color: #E6FEF1;
}
.btnWrapper:hover,
.btnWrapper:focus{
  opacity: 1;
  animation: none;
  transition: all .5s ease-in-out;
}

@keyframes movebtn {
  0%{
    transform: translateY(0px);
  }
  50%{
    transform: translateY(10px);
  }
  100%{
    transform: translateY(0px);
  }
}