.clutch {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  /* height: 60px; */
}

.text{
  font-family: 'Mulish', sans-serif;
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}
  
@media (min-width: 768px) {
  .clutch {
    flex-direction: row;
  }

  .separator{
    width: 30px;
  }

  .wrapper {
    display: flex;
    align-items: center;
  }

  .text{
    text-align: left;
    margin-bottom: 0;
    margin-right: 20px;
  }
}

@media (min-width: 1920px) { 
  .text{
    font-size: 32px;
  }
}